<template>
  <div class="accountManager">
    <!-- floating button [for add] -->
    <v-btn
      fab
      absolute
      class="plusButton"
      right
      bottom
      color="red accent-2"
      @click="addDialog = !addDialog"
    >
      <v-icon color="#fff">mdi-plus</v-icon>
    </v-btn>
    <!-- delete dialog box -->
    <v-dialog v-model="deleteDialog" width="500">
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>
          Confirmation
        </v-card-title>

        <v-card-text>
          <br />
          <b>Danger</b> <br />
          Delete will unassign all dealers tagged to this account manager. Are
          you sure you want to delete this car type:
          <b>{{ delAccountManager.name }}</b
          >?
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="doDelAccountManager()">
            Yes
          </v-btn>
          <v-btn color="primary" text @click="deleteDialog = false">
            No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="addDialog" max-width="500px">
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>
          Add Account Manager
        </v-card-title>
        <v-card-text>
          <v-text-field
            v-model="addAccountManager.name"
            label="Name"
          ></v-text-field>
          <v-text-field
            v-model="addAccountManager.mobilePhone"
            label="Phone"
          ></v-text-field>
          <v-file-input
            @change="chooseImage"
            v-model="imgFile"
            clearable
            label="Picture"
            accept="image/jpg,image/jpeg,image/png,image/gif"
          ></v-file-input>
          <v-select
            v-model="addAccountManager.userId"
            :items="adminList"
            item-value="id"
            item-text="name"
            label="Select User"
          >
          </v-select>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn text color="primary" @click="doAddAccountManager()"
            >Submit</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row>
      <v-col cols="12" sm="6" md="3">
        <v-text-field
          @keyup.enter.native="searchAM()"
          v-model="amSearch"
          label="ʕ•́ᴥ•̀ʔっ♡¯\_(ツ)_/¯"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="6" md="3">
        <v-btn
          @click="searchAM()"
          @keyup.enter.native="searchAM()"
          large
          elevation
          color="primary"
        >
          Search
        </v-btn>
      </v-col>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="amList"
      :page.sync="pagination.page"
      :items-per-page="pagination.limit"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      @update:options="sortItems"
      hide-default-footer
      class="elevation-1"
    >
      <template v-slot:item="row">
        <tr>
          <td>{{ row.item.id }}</td>
          <td>{{ row.item.name }}</td>
          <td>
            <img
              style="height:50px; padding-top: 6px"
              v-if="row.item.profilePic != null"
              v-bind:src="
                'https://ucars-bucket.s3-ap-southeast-1.amazonaws.com/' +
                  row.item.profilePic
              "
            />
          </td>
          <td>{{ row.item.mobilePhone }}</td>
          <td>
            <v-btn
              class="mx-2"
              fab
              dark
              small
              color="primary"
              @click="delButtonClick(row.item)"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </td>
        </tr>
      </template>
    </v-data-table>
    <v-pagination
      v-model="pagination.page"
      :circle="true"
      :disabled="false"
      :length="pagination.noOfPage"
      :page="pagination.page"
      update:page
      :total-visible="10"
      @input="paginationChangeHandler"
    ></v-pagination>
  </div>
</template>

<script>
import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import uploadImage from "../../../services/image-uploader";

import getAxios from "../../../services/axios-get";
import postAxios from "services/axios-post";
import deleteAxios from "../../../services/axios-delete";

Vue.use(VueAxios, axios);

export default {
  data() {
    return {
      amSearch: "",
      token: "",
      pageCount: 0,
      addDialog: false,
      imgFile: null,
      imgUrl: null,
      pagination: {
        limit: 10,
        noOfPage: 1,
        page: 1,
      },
      headers: [
        { text: "Id", value: "id" },
        { text: "Name", value: "name" },
        { text: "Profile Picture", value: "profilePic" },
        { text: "Phone", value: "mobilePhone" },
      ],
      amList: [],
      adminList: [],
      delAccountManager: {
        id: "",
        name: "",
      },
      deleteDialog: false,
      addAccountManager: {
        mobilePhone: "",
        name: "",
        profilePicture: "",
        userId: "",
      },
      userList: [],
      sortValue: null,
      sortBy: null,
      sortDesc: null,
    };
  },
  mounted() {
    this.fetchAMList();
    this.fetchAllAdminList();
    this.fetchCredentials();
  },
  methods: {
    sortItems({ sortBy, sortDesc }) {
      let columnName = sortBy[0];
      if (columnName && sortDesc[0] === true) {
        this.sortValue = columnName + ",desc";
      } else if (columnName && sortDesc[0] === false) {
        this.sortValue = columnName;
      } else {
        this.sortValue = null;
      }
      this.fetchAMList();
    },
    delButtonClick(item) {
      this.delAccountManager.id = item.id;
      this.delAccountManager.name = item.name;
      this.deleteDialog = true;
    },
    doDelAccountManager() {
      const self = this;
      const param = {
        accountManagerId: self.delAccountManager.id,
      };
      deleteAxios(
        `${process.env.VUE_APP_SERVER_URL}/userSystem/accountManager`,
        param,
        self.token
      ).then(res => {
        if (res.data) {
          self.deleteDialog = false;
          self.fetchAMList();
          self.$message({
            message: "Account Manager Deleted Successfully!",
            type: "success",
          });
        }
      });
    },
    chooseImage() {
      this.imgUrl = URL.createObjectURL(this.imgFile);
    },
    searchAM() {
      this.fetchAMList();
    },
    paginationChangeHandler(pagination) {
      this.pagination.page = pagination;
      this.fetchAMList();
    },
    fetchAMList() {
      const self = this;
      const params = {
        page: self.pagination.page - 1,
        size: self.pagination.limit,
        searchContent: self.amSearch,
        sort: self.sortValue,
      };
      getAxios(
        `${process.env.VUE_APP_SERVER_URL}/userSystem/accountManager`,
        params
      ).then(res => {
        if (res.data) {
          self.amList = res.data.data.content;
          self.pagination.noOfPage = res.data.data.totalPages;
        }
      });
    },
    fetchAllAdminList() {
      const self = this;
      const params = {
        page: self.pagination.page - 1,
        size: 1000,
        searchContent: self.amSearch,
      };
      getAxios(
        `${process.env.VUE_APP_SERVER_URL}/userSystem/adminData`,
        params
      ).then(res => {
        if (res.data) {
          self.adminList = res.data.data.content;
          self.pagination.noOfPage = res.data.data.totalPages;
        }
      });
    },
    async doAddAccountManager() {
      const self = this;
      try {
        var attachmentPath = null;
        if (self.imgFile) {
          const imgRes = await uploadImage(self.imgFile);
          attachmentPath = imgRes.data.data;
        }
        if (attachmentPath) {
          self.addAccountManager.profilePicture = attachmentPath;
        }
        postAxios(
          `${process.env.VUE_APP_SERVER_URL}/userSystem/addAccountManager`,
          self.addAccountManager,
          self.token
        ).then(res => {
          self.fetchAMList();
          if (res.data) {
            self.addDialog = false;
            self.$emit("add");
            self.reset();
          }
        });
      } catch (e) {
        self.$emit("error", e);
        console.error(e);
      }
    },
    fetchCredentials() {
      var self = this;
      const token = localStorage.getItem("jwt");
      if (token) {
        self.token = token;
      } else {
        self.showAlert("error", "unauthorized..");
      }
    },
  },
};
</script>
